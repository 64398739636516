<template>
    <div class="container">
        <div class="page-name ml-2">
            <h1 ml-4>Works done for our <span style="color:#D6A016">Customers</span></h1>
        </div>
        <v-container class="services-container">
            <v-row no-gutters>
                <v-col v-for="(image, index) in images" :key="index" cols="12" xs="12" sm="12" md="6" lg="4" xl="3"
                    class="pa-5 mb-4">
                    <v-hover v-slot="{ hover }">
                        <v-card v-if="image.show" @click="zoomImage(index)" class="mx-auto"  min-height="380px" max-width="400">
                            <v-img class="image-card white--text align-end" height="220px" :src="image.images[0]">
                                <v-expand-transition>
                                    <div v-if="hover"
                                        class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal text-h4 white--text"
                                        style="height: 100%;">
                                        See Gallery
                                    </div>
                                </v-expand-transition>
                            </v-img>

                            <v-card-subtitle class="pb-0">
                                <v-chip class="mb-3" color="grey lighten-2">
                                    <v-icon left dense>
                                        mdi-map-marker
                                    </v-icon>
                                    {{ image.address }}

                                </v-chip>
                            </v-card-subtitle>

                            <v-card-text class="pl-5 text--primary">
                                <div> {{ image.description }}</div>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>

            <v-dialog v-model="dialog" overlay-opacity="0.75"  transition="dialog-bottom-transition" max-width="750">
                <v-carousel dark class="carousel" v-if="dialog" height="auto">
                    <v-carousel-item class="item-carousel" v-for="(item, i) in zoomedUrl" :key="i" eager>
                        <v-img :src="item" eager />

                    </v-carousel-item>
                </v-carousel>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>


import { db } from "@/utils/firebase";
export default {
    data() {
        return {
            activeIndex: 1,
            images: [],
            dialog: false,
            zoomedUrl: [],
        };
    },
    methods: {
        zoomImage(index) {
            this.zoomedUrl = [];
            this.zoomedUrl = this.images[index].images;
            this.dialog = true;
        },

        async getGallery() {
            try {
                let gallery = await db.collection('gallery').where("show", "==", true).get();
                gallery.forEach((galleryItem) => {
                    this.images.push(galleryItem.data())
                })

            } catch (error) {
                console.log(error);
            }

        }
    },

    mounted() {
        this.getGallery()
    }
};
</script>


<style>
.page-name h1 {
    @media (max-width: 1200px) {
        font-size: 25px;
    }
}


.item-carousel .v-image__image--cover {
    background-size: contain;
    background-color: #00000070;
}

.carousel {
    height: auto;
    max-height: 90vh;
}

.image-card {
    width: 380px;
    object-fit: contain;

}

.v-image__image--cover {
    background-size: cover;
    background-position: top center !important;
    ;
}

.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}

.container{
    padding: 10px!important;
}
</style>