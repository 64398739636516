<template>
  <v-app id="app">
    <div class="main">
      <menuPhoneBar class="phoneMenu" />
      <menuBar class="menu" />

      <router-view />
    </div>
    <footerBar class="footer" />

  </v-app>
</template>


<script>
import menuBar from '@/components/MenuBar.vue';
import menuPhoneBar from '@/components/MenuPhoneBar.vue';
import footerBar from '@/components/FooterBar.vue'
export default {
  name: 'HomeView',
  components: {
    menuBar,
    footerBar,
    menuPhoneBar
  }
}
</script>


<style lang="scss" scoped>
@import "@/responsive";

* {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 0;
}

#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100% !important;
}

.main {
  min-height: 90%;

  @media (max-width: 1200px) {
    min-height: 90%;
  }

  @include responsive(mobile) {
    min-height: 90%;
  }
}

.menu {

  @media (max-width: 1200px) {
    display: none;
  }

  @include responsive(mobile) {
    display: none;
  }
}

.phoneMenu {

  @media (min-width: 1200px) {
    display: none;
  }

  @include responsive(desktop) {
    display: none;
  }
}

.footer {
  position: relative;
}
</style>
