<template>
    <div>
        <v-app-bar color="dark-4" elevation="0" prominent dark>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

            <div class="logo">
                <img class="img-logo" src="@/assets/original recor.png" alt="Storm Proof">
            </div>

        </v-app-bar>
        <v-navigation-drawer v-model="drawer" class="drawer dark-4 accent-4" dark absolute temporary>
            <v-list nav dense>
                <v-list-item-group v-model="group" active-class="amber darken-2 white--text">
                    <v-list-item class="mb-2" to="/">
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item>

                    <v-list-item class="mb-2" to="/services">
                        <v-list-item-icon>
                            <v-icon>mdi mdi-account-wrench-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Services</v-list-item-title>
                    </v-list-item>

                    <v-list-item class="mb-2" to="/gallery">
                        <v-list-item-icon>
                            <v-icon>mdi mdi-view-gallery-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Gallery</v-list-item-title>
                    </v-list-item>

                    <v-list-item class="mb-2" to="/contact">
                        <v-list-item-icon>
                            <v-icon>mdi mdi-contacts-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Contact</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <div class=" logo-bottom pa-2">
                <img class="img-logo" src="@/assets/original recor.png" alt="Storm Proof">
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: 'MenuPhoneBar',

    data() {
        return {
            page: "home",
            drawer: false,
            group: null,

        }
    },
}
</script>


<style>
.logo {
    margin-left: -48px;
}


.logo-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 70vh;
}

.logo-bottom .v-list{
    height: 30vh!important;
}
.logo-bottom img{
    max-height: 130px;
}
</style>