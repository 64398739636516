<template>
  <div>
    <div class="carrousel">
      <v-carousel height="70vh" :touchless="true" cycle :show-arrows="false" hide-delimiters>
        <div class="slogan">
          <h1>From the <span style="color:#D6A016">ground</span> up, <br> we’ve got you <span
              style="color:#D6A016">covered</span></h1>
        </div>
        <v-carousel-item v-for="(img, i) in carrouselImages" :key="i" :src=img eager>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="container-1">
      <div class="left">
        <div class="skills">
          <p> <span style="color:#D6A016">Storm Proof</span> are experts in disaster restoration services for residential
            and commercial roofing.
            We Specialize in storm damage repair, replacement and insurance claim restoration. Storm Proof contractors can
            assist anyone who is looking to have their roof repaired or replaced.</p>
        </div>
        <div class="checkList">
          <div class="check">
            <v-icon left>
              mdi-check-circle-outline
            </v-icon>
            <span>We utilize the most quality material available</span>

          </div>

          <div class="check">
            <v-icon left>
              mdi-check-circle-outline
            </v-icon>
            <span>Highly skilled, experienced and competent team</span>
          </div>

          <div class="check">
            <v-icon left>
              mdi-check-circle-outline
            </v-icon>
            <span>Licensed, insured, certified and bonded</span>
          </div>

        </div>

        <h1 class="mt-4 our-services-title">Our <span style="color:#D6A016">Services</span></h1>

        <div class="services">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="4" class="mb-4 pa-3 pl-0">

              <v-card class="mx-auto" max-width="344">
                <v-img :src=servicesImages.img1?.url height="200px"></v-img>

                <v-card-title style="color:#D6A016">
                  {{ servicesImages.img1?.title }}
                </v-card-title>

              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="4" class="mb-4 pa-3">
              <v-card class="mx-auto" max-width="344">
                <v-img :src=servicesImages.img2?.url height="200px"></v-img>

                <v-card-title style="color:#D6A016">
                  {{ servicesImages.img2?.title }}
                </v-card-title>

              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" class="mb-4 pa-3">



              <v-card class="mx-auto" max-width="344">

                <v-img :src=servicesImages.img3?.url height="200px">
                  <div class="service-more">
                    <v-btn plain to="/services" style="color:#D6A016" x-large> MORE
                      <v-icon large color="#D6A016"> mdi mdi-arrow-right </v-icon></v-btn>

                  </div>
                </v-img>
                <v-card-title style="color:#D6A016">
                  {{ servicesImages.img3?.title }}
                </v-card-title>

              </v-card>

            </v-col>
          </v-row>
        </div>
      </div>
      <div class="rigth">
        <div class="quote">
          <div class="quote-form">
            <div class="title-contariner">
              <h1 class="mb-4">Request a <span style="color:#D6A016">Quote</span></h1>
            </div>
            <v-form ref="form" v-model="valid" @submit.prevent>
              <v-container>
                <v-row>
                  <v-col class="col-padding" cols="12" sm="12" md="12" lg="12">

                    <v-text-field v-model="name" filled background-color="white" color="#d6a016" label="Name"
                      required></v-text-field>
                  </v-col>
                  <v-col class="col-padding" cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="phoneNumber" filled background-color="white" color="#d6a016"
                      label="Phone Number" required></v-text-field>

                  </v-col>
                  <v-col class="col-padding" cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="email" filled background-color="white" color="#d6a016" :rules="emailRules"
                      label="E-mail" required></v-text-field>

                  </v-col>
                  <v-col class="col-padding" cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="address" filled background-color="white" color="#d6a016" label="Address"
                      required></v-text-field>

                  </v-col>
                  <v-col class="col-padding" cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="city" filled background-color="white" color="#d6a016" label="City"
                      required></v-text-field>

                  </v-col>
                  <v-col class="col-padding" cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="zip" filled background-color="white" color="#d6a016" label="Zip Code"
                      required></v-text-field>

                  </v-col>
                  <div class="btn-contariner">
                    <v-btn color="#D6A016" @click="sendEmail" style="color: white;" class="pd-4" type="submit"
                      :disabled="!valid">
                      Request
                    </v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-form>
          </div>
        </div>
      </div>
      <v-snackbar top v-model="snackbar" :timeout="timeout">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>

import { db } from "@/utils/firebase";
import emailjs from '@emailjs/browser';

export default {
  name: 'HomeView',
  components: {
  },


  data() {

    return {
      name: '',
      phoneNumber: '',
      email: '',
      address: '',
      city: '',
      zip: '',
      snackbar: false,
      snackText: '',
      timeout: 2000,
      valid: false,

      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],

      carrouselImages: [],
      servicesImages: [],
      mensaje: ''

    }
  },

  methods: {

    async validate() {
      return this.$refs.form.validate()
    },

    clear() {
      this.$refs.form.reset();
    },

    async getImages() {
      try {
        let version = await db.collection('carrousel').doc('actualVersion').get();
        version = version.data().version;
        let v1 = db.collection('carrousel').doc(version).get();

        v1.then(v1 => {
          this.carrouselImages = v1.data().images;
        })

      } catch (error) {
        console.log(error);
      }

    },

    async getServicesImages() {
      try {
        let services = await db.collection('carrousel').doc('homeServices').get();

        this.servicesImages = services.data();


      } catch (error) {
        console.log(error);
      }

    },

    async sendEmail() {
      let valid = await this.validate()
      console.log(valid);
      if (valid) {

        const templateParams = {
          name: this.name,
          phoneNumber: this.phoneNumber,
          email: this.email,
          city: this.city,
          address: this.address,
          zip: this.zip
        };


        emailjs.send(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
          templateParams,
          process.env.VUE_APP_EMAILJS_USER_ID
        )
          .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
            this.snackText = "Email Send!";
            this.snackbar = true;

            this.clear();
          })
          .catch((error) => {
            console.error('Error sending email:', error);
            this.snackText = "Error to send Email.";
            this.snackbar = true;


            this.clear();
          });

      }
      else {
        this.snackText = "Error to send Email.";
        this.snackbar = true;
        this.clear();
      }
    },

  },

  beforeMount() {
    this.getImages()
    this.getServicesImages()
  }
}
</script>

<style lang="scss" scoped>
@import "@/responsive";

.carrousel {
  height: 70vh !important;
}

.slogan {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.slogan h1 {
  color: white;
  font: bold;
  font-size: 70px;


  @include responsive(mobile) {
    font-size: 35px;
  }

  @media (max-width: 1000px) {
    font-size: 35px;
  }
}

.container-1 {
  display: flex;
  justify-content: space-between;
  align-items: top;
  width: 100%;
  margin-top: 40px;


  @include responsive(mobile) {
    flex-direction: column;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
}

.left {
  flex: 1;
  width: 50%;


  @include responsive(mobile) {
    width: 100%;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.skills {
  display: flex;
  justify-content: start;
  align-items: start;
  font-size: 22px;
  text-align: justify;
  padding: 30px;
  padding-left: 70px;


  @include responsive(mobile) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 30px;
  }
}

.rigth {
  flex: 1;
  width: 50%;


  @include responsive(mobile) {
    width: 100%;
    margin-bottom: 40px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 40px;
  }

}

.checkList {
  padding: 20px;
  padding-top: 0px;
  padding-left: 70px;
  font-size: 22px;


  @include responsive(mobile) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 30px;
  }

}

.checkList i {
  color: #D6A016 !important;
}

.checkList .check {
  margin-bottom: 10px;
}

.our-services-title {
  padding-left: 65px;


  @include responsive(mobile) {
    padding-left: 30px;
  }

  @media (max-width: 768px) {
    padding-left: 30px;
  }
}

.services {
  margin-bottom: 50px;
  margin-top: 20px;
  padding-left: 70px;
  padding-right: 30px;
  width: 750px;
  display: flex;
  justify-content: space-between;


  @include responsive(mobile) {
    padding: 30px;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 30px;
    justify-content: center;
    width: 100%;
  }


}

.service .service-title {
  padding: 7px;
  background-color: rgba(0, 0, 0, 0.70);
  position: absolute;
  display: flex;
  z-index: 2;
  box-sizing: border-box;
  margin-top: 120px;
  border-radius: 10px;


  @include responsive(mobile) {
    padding: 7px;
    margin-top: 190px;
  }

  @media (max-width: 768px) {
    padding: 7px;
    margin-top: 190px;
  }
}

.service-more {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.70);
  position: absolute;
  display: flex;
  z-index: 3;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.service-title p {
  margin: 0;
}

.service-more p {
  margin: 0;
  font-size: 25px;
  text-decoration: underline;

}

.service-more a {
  display: flex;
  text-decoration: none;

}

.quote {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  padding-left: 170px;
  padding-right: 170px;


  @include responsive(mobile) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.quote-form {
  background-color: #F0F2F7;
  width: 100%;
  padding: 50px;
  border-radius: 10px;

  @include responsive(mobile) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 30px;
  }
}

.quote-form fieldset {
  background-color: white;
}

.btn-contariner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title-contariner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-contariner button {
  width: 200px;
  height: 40px;
  padding: 10px !important;
}

.col-padding {
  padding: 0px !important;
}
</style>
