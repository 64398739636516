<template>
    <div class="global">
        <v-app-bar class="menu wrap-nav-items" color="dark-4" elevation="0" prominent dark>
            <div class="logo pl-6">
                <img class="img-logo" src="@/assets/original recor.png" alt="Storm Proof">
            </div>

            <div class="menu-group">
                <div class="contact-tags">
                    <a href="mailto:Info@stormproofconstructionllc.com">
                        <v-chip style="cursor: pointer;" class="ma-2" color="transparent" label text-color="#D6A016">
                            <v-icon left>
                                mdi-email-outline
                            </v-icon>
                            <span>
                                Info@stormproofconstructionllc.com
                            </span>
                        </v-chip>
                    </a>
                    <a href="tel:+5044168276">
                        <v-chip style="cursor: pointer;" class="ma-2" color="transparent" label text-color="#D6A016">
                            <v-icon left>
                                mdi-phone
                            </v-icon>
                            <span>
                                504-417-8276
                            </span>
                        </v-chip>
                    </a>
                </div>

                <div class="root-buttos">
                    <v-btn plain to="/" large> Home </v-btn>
                    <v-btn plain to="/services" large> SERVICES </v-btn>
                    <v-btn plain to="/gallery" large> GALLERY </v-btn>
                    <v-btn plain to="/contact" large> CONTACT </v-btn>
                </div>
            </div>
            <div class="social-buttons">
                <v-btn href="https://www.facebook.com/profile.php?id=100090165154504&mibextid=LQQJ4d" target="_blank" icon>
                    <v-icon large color="#D6A016">
                        mdi-facebook
                    </v-icon>
                </v-btn>
                <v-btn href="https://www.instagram.com/storm.proofconstruction/" target="_blank" icon>
                    <v-icon large color="#D6A016">
                        mdi-instagram
                    </v-icon>
                </v-btn>
            </div>

        </v-app-bar>
    </div>
</template>

<script>
export default {
    name: 'MenuBar',

    data() {
        return {
            page: "home",
            drawer: false,
            group: null,

        }
    },
}
</script>

<style lang="scss">
.v-toolbar__content {
    width: 100% !important;
}

.menu {
    display: flex;
    justify-content: center;
    height: 15vh !important;
    min-height: 140px;
    width: 100% !important;

    @media (max-width: 1200px) {
        height: 22vh !important;
    }

    @media (max-width: 900px) {
        height: 15vh !important;
    }


}

.logo {
    flex: 1;
    height: 140px;
    display: flex;
    justify-content: center;
}

.menu-group {

    flex: 3;
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    height: 100%;

    @media (max-width: 1000px) {
        display: none;
    }
}

.root-buttos {
    height: 100%;
    flex: 4;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 50px;
    margin-left: 50px;

}

.contact-tags {
    display: flex;
    justify-content: right;
    margin-right: -150px;
}

.v-btn--active {
    background-color: #D6A016 !important;
}

.v-btn:before {
    background-color: transparent !important;
    opacity: 0.9 !important;
}

.social-buttons {
    width: 300px;
    flex: 1;
    display: flex;
    justify-content: right;
}

.social-buttons i:hover {
    color: white !important;
}

.social-buttons i {
    margin-top: 6px;
    margin-left: 10px;
    cursor: pointer;
}

.social-buttons span {
    font-size: 16px;
}

.drawer {
    height: 100%;
}
</style>