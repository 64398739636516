<template>
  <div class="container">
    <div class="page-name">
      <h1>Contact<span style="color:#D6A016"> Us</span></h1>
    </div>
    <div class="background">
      <div class="form">
        <div class="title-contariner mt-4 ml-2">
          <h1 class="mb-4">Interested <span style="color:white">in our services? </span></h1>
        </div>
        <v-form class="pa-5" ref="form" v-model="valid" @submit.prevent>
          <v-container>
            <v-row>
              <v-col class="pa-3" cols="12" md="6" sm="12">

                <v-text-field v-model="name" filled background-color="white" color="#d6a016" label="Name"
                  required></v-text-field>
              </v-col>
              <v-col class="pa-3" cols="12" md="6" sm="12">
                <v-text-field v-model="phoneNumber" filled background-color="white" color="#d6a016" label="Phone Number"
                  required></v-text-field>

              </v-col>
              <v-col class="pa-3" cols="12" md="6" sm="12">
                <v-text-field v-model="email" filled background-color="white" color="#d6a016" :rules="emailRules"
                  label="E-mail" required></v-text-field>

              </v-col>
              <v-col class="pa-3" cols="12" md="6" sm="12">
                <v-text-field v-model="address" filled background-color="white" color="#d6a016" label="Address"
                  required></v-text-field>

              </v-col>
              <v-col class="pa-3" cols="12" md="12" sm="12">
                <v-textarea v-model="message" class="text-fields-form" filled color="#d6a016" background-color="white"
                  label="Message" required></v-textarea>

              </v-col>
              <div class="btn-contariner">
                <v-btn color="#D6A016" @click="sendEmail" style="color: white;" class="pd-4" type="submit"
                  :disabled="!valid">
                  Send you Message.
                </v-btn>
              </div>
            </v-row>
          </v-container>
        </v-form>
        <v-snackbar top v-model="snackbar" :timeout="timeout">
          {{ snackText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <div class="social">
        <div class="items">
          <div class="item">
            <v-icon class="mr-2" medium color="#D6A016">
              mdi-map-marker
            </v-icon>
            <span style="color: white;">
              New Orleans, Louisiana
            </span>
          </div>

          <div class="item">
            <v-icon class="mr-2" medium color="#D6A016">
              mdi-phone
            </v-icon>
            <span style="color: white;">
              504-417-8276
            </span>


          </div>

          <div class="item">
            <v-icon class="mr-2" medium color="#D6A016">
              mdi-email-outline
            </v-icon>
            <span style="color: white;">
              Info@stormproofconstructionllc.com
            </span>
          </div>
        </div>

        <div class="follow mb-3">
          <h2 style="color: white" class="mb-4">Follow <span style="color:#D6A016"> US</span></h2>
          <div class="icons">
            <v-btn href="https://www.facebook.com/profile.php?id=100090165154504&mibextid=LQQJ4d" target="_blank" icon>
              <v-icon large color="#D6A016">
                mdi-facebook
              </v-icon>
            </v-btn>
            <v-btn class="ml-3" href="https://www.instagram.com/storm.proofconstruction/" target="_blank" icon>
              <v-icon large color="#D6A016">
                mdi-instagram
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import emailjs from '@emailjs/browser';
export default {
  name: 'ContactView',
  components: {
  },


  data() {

    return {
      name: '',
      phoneNumber: '',
      email: '',
      address: '',
      message: '',
      valid: false,
      snackbar: false,
      snackText: '',
      timeout: 2000,

      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],

    }
  },

  methods: {
    async validate() {
      return this.$refs.form.validate()
    },

    clear() {
      this.$refs.form.reset();
    },

    async sendEmail() {
      let valid = await this.validate()
      console.log(valid);
      if (valid) {

        const templateParams = {
          name: this.name,
          phoneNumber: this.phoneNumber,
          email: this.email,
          message: this.message,
          address: this.address,
        };


        emailjs.send(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_TEMPLATE_CONTACT_ID,
          templateParams,
          process.env.VUE_APP_EMAILJS_USER_ID
        )
          .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
            this.snackText = "Email Send!";
            this.snackbar = true;

            this.clear();
          })
          .catch((error) => {
            console.error('Error sending email:', error);
            this.snackText = "Error to send Email.";
            this.snackbar = true;


            this.clear();
          });

      }
      else {
        this.snackText = "Error to send Email.";
        this.snackbar = true;
        this.clear();
      }
    },

  },

  mounted() {
  }
}
</script>



<style lang="scss" scoped>
@import "@/responsive";

.background {
  position: relative;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/storm-proof-bc7b1.appspot.com/o/Images%2FServices%2FHome%2FWhatsApp%20Image%202023-04-27%20at%207.16.59%20PM.jpeg?alt=media&token=f94b4782-c134-488a-b7e0-1e03eba8f9e3");


  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  @include responsive(mobile) {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}


.form {
  width: 70%;
  background-color: #d6a01667;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 100%;
  }

  @include responsive(mobile) {
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 100%;
  }

}

.social {
  width: 30%;
  background-color: rgba(0, 0, 0, 0.70);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    height: 30%;
    width: 100%;
  }

  @include responsive(mobile) {
    display: flex;
    flex-direction: column;
    height: 30%;
    width: 100%;
  }
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.social .item {
  margin-top: 30px;
}

.social .follow {
  margin-top: 10px;
}

.follow i:hover {
  color: white !important;
}

.follow i {
  cursor: pointer;
}

.row-form {
  padding: 50px;
  display: flex;
  justify-content: center;
}

.form fieldset {
  background-color: white;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.theme--light.v-btn.v-btn--disabled {
  color: rgba(255, 255, 255, 0.6) !important;
}

.btn-contariner {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 15px;
}
</style>