import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBeSa-KB2Z_BgkOppJUyWmAETxgkroJU-o",
    authDomain: "storm-proof-bc7b1.firebaseapp.com",
    projectId: "storm-proof-bc7b1",
    storageBucket: "storm-proof-bc7b1.appspot.com",
    messagingSenderId: "368727541999",
    appId: "1:368727541999:web:5d757900a3d31a18b5731a",
    measurementId: "G-8B62CCR4EW"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;