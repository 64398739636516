<template>
    <div class="container">
        <div class="page-name">
            <h1>Our <span style="color:#D6A016">Services</span></h1>
        </div>
        <v-container class="services-container">
            <v-row no-gutters>
                <v-col v-for="(service, index) in AllServices" :key="index" cols="12" xs="12" sm="12" md="6" lg="4" xl="3"
                    class="pa-5 mb-4">
                    <v-card class="mx-auto" height="100%" max-width="400">
                        <v-img class="white--text align-end" height="280px" max-width="480" :src=service.image>
                            <v-card-title style="padding: 5px; padding-left: 10px; background-color: rgba(0, 0, 0, 0.5);">
                                {{ service.serviceName }}</v-card-title>
                        </v-img>

                        <v-card-subtitle class="pb-0">
                            <v-chip v-if="service.tag" class="mb-3 font-weight-bold" color="grey lighten-2" text- label
                                text-color="grey darken-3">
                                <v-icon small left>
                                    mdi-label
                                </v-icon>
                                {{ service.tag }}

                            </v-chip>
                        </v-card-subtitle>
                        <v-card-text class="pl-5 text--primary">
                            <div>{{ service.description }}</div>

                            <div class="mt-3" style="font-size: 16px; color: #D6A016;">Includes:</div>
                            <div class="mt-1" v-for="(include, index) in service.includes" :key="index">
                                <v-icon color="#D6A016">
                                    mdi mdi-circle-medium
                                </v-icon>
                                {{ include }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import { db } from "@/utils/firebase";
export default {
    data() {
        return {
            AllServices: [],
        };
    },
    methods: {

        async getServices() {
            try {
                let services = await db
                    .collection('services')
                    .where("show", "==", true)
                    .orderBy("position")
                    .get();
                services.forEach((service) => {
                    this.AllServices.push(service.data());
                })


            } catch (error) {
                console.log(error);
            }

        }
    },

    mounted() {
        this.getServices()
    }
};
</script>

<style lang="scss" scoped>
@import "@/responsive";

.container {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;

    @include responsive(mobile) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.page-name {
    width: 100%;
    margin-top: 10px;
    padding-left: 20px;
}

.page-name h1 {
    @media (max-width: 1200px) {
        font-size: 25px;
    }
}

.services-container {
    width: 100%;
}
</style>